import { useState, useEffect } from "react";
import {
  addPetFood,
  getPetFoods,
  deletePetFood,
} from "../../utils/back4AppApi";
import {
  KinshipPetProfile,
  BrandedFood,
} from "../../utils/kinshipTypes";
import {
  Table,
  TableBody,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Stack,
  TableContainer,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { translate } from "../../utils/localisation";

interface Props {
  pet?: KinshipPetProfile;
  onAction?: Function;
}

export function Foods({ 
    pet,
    onAction = () => {}, 
}: Props) {
  const [foodSearch, setFoodSearch] = useState("");
  const [newFoodId, setNewFoodId] = useState("");
  const [foodSuggestions, setFoodSuggestions] = useState([]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const res = await getPetFoods(`?query=${foodSearch}`);
      setFoodSuggestions(res.data.foods);
      if (res.data.foods.length > 0) {
        if (res.data.foods[0].productName === undefined) {
          setNewFoodId(res.data.foods[0].petFood.id);
        } else {
          setNewFoodId(res.data.foods[0].id);
        }
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn)
  }, [foodSearch])
  const onFoodAction = async (event: any, ...args: Array<string | null>) => {
    event.preventDefault();
    const actionType = args[0];
    const actionArg = args[1];
    const actionHasConfirmDialogStep = args[2];
    if (actionHasConfirmDialogStep === "NEEDS_CONFIRMATION") {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(translate('are_you_sure_delete_food'))) {
        onFoodAction(event, actionType, actionArg);
      }
      return;
    }
    if(actionType === "ADD") {
      const data = {
        branded: [{
          brandedFoodId: newFoodId,
        }]
      };
      const result = await addPetFood(
        pet?.kinshipPetId,
        data
      );
      onAction(result);
    } else if(actionType === "DELETE") {
      const result = await deletePetFood(
        pet?.kinshipPetId,
        newFoodId
      );
      onAction(result);
    }
  };
  return (
    <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
    <Grid container sx={{ minWidth: 1200 }}>
      <Grid item xs={6}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
      {translate("foods")}
      </Typography>
      <TableContainer sx={{ minWidth: 1200 }}>
        <Table sx={{width: "max-content"}}>
        <TableBody>
          {pet?.petFoods?.branded?.map((food: BrandedFood, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell align="left" colSpan={2}>
                    {food?.productName}
                </TableCell>
                <TableCell align="left" colSpan={2}>
                    <Stack direction="row" spacing={1}>
                    <IconButton
                        color="primary"
                        aria-label={translate('delete')}
                        onClick={(event) => onFoodAction(event, "DELETE", food?.productName)}
                    >
                        <DeleteIcon />
                    </IconButton>
                    </Stack>
                </TableCell>
              </TableRow>
            );
            }
        )}
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <TextField
                  id="newName"
                  name="newName"
                  label={translate('add_food')}
                  variant="outlined"
                  size="small"
                  value={foodSearch}
                  onChange={(e) => setFoodSearch(e.target.value)}
              />
              <Select
                displayEmpty
                value={newFoodId}
                onChange={(e) => setNewFoodId(e.target.value)}
                sx={{ height: 40 }}
              >
                {foodSuggestions.map((food: BrandedFood, index: number) => {
                  if (food.productName === undefined) {
                    return (
                      <MenuItem value={food.petFood.id}>
                         {food.petFood.productName}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem value={food.id}>
                         {food.productName}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={(event) => onFoodAction(event, "ADD")}
                  >
                    {translate('add_food')}
                  </Button>
              </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Box>
 );
}
