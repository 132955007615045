import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  getVaccineTypes,
} from "../../utils/kinshipApi";
import {
  petPatch,
  editPetVaccination,
  deletePetVaccination,
  deleteAllPetVaccination,
} from "../../utils/back4AppApi";
import {
  KinshipPetProfile,
  MedicalRecord,
  Vaccination,
  VaxType,
} from "../../utils/kinshipTypes";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  Stack,
  Grid,
  TableContainer,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "react-datepicker/dist/react-datepicker.css";
import { datePickerFormatDate, getDateYearPlusOne, getLuxonDate, resolveDueString} from "../../utils/utils";
import { translate } from "../../utils/localisation";

interface Props {
  record?: MedicalRecord;
  pet?: KinshipPetProfile;
  onActionVaccination?: Function;
}

export function Vaccinations({ 
    record,
    pet,
    onActionVaccination = () => {}, 
}: Props) {
  const [vaxTypeList, setVaxTypeList] = useState<Array<VaxType>>([]);
  const [newVaxName, setNewVaxName] = useState("");
  const [vaxType, setVaxType] = useState<string>("");
  const [newVaxDate, setNewVaxDate] = useState<Date | null>(new Date());
  const [newVaxRenewalDate, setNewVaxRenewalDate] = useState<Date| null>(getDateYearPlusOne());
  const [oldVaccineTypeToUpdate, setOldVaccineTypeToUpdate] = useState<string>();
  const [isUpdateVaccination, setIsUpdateVaccination] = useState<boolean>(false);
  const renewalDatePickerRef = useRef<any>();

  useEffect(() => {
    const mountAndInit = async () => {
      const vType: any = await getVaccineTypes();
      const vTypeList: Array<VaxType> = vType?.data?.vaccines as Array<VaxType>;
      const vTypeFiltered = [] as Array<VaxType>;
      for(const item of vTypeList) {
        if(item?.species?.some(speciesStr => pet?.petType === speciesStr)) {
          vTypeFiltered.push(item);
        }};
      setVaxTypeList(vTypeFiltered ?? []);
    };
    mountAndInit();
  }, [pet?.petType]);

  const onEditVaccinationSubmit = (event: any, vaccination: Vaccination) => {
    event.preventDefault();
    setIsUpdateVaccination(true);
    setOldVaccineTypeToUpdate(vaccination.type ?? "");
    setNewVaxName(vaccination.vaccineName ?? "");
    setNewVaxDate(new Date(datePickerFormatDate(getLuxonDate(vaccination.administeredDate))));
    setNewVaxRenewalDate((vaccination.renewalDate || getLuxonDate(vaccination.renewalDate).isValid) ? new Date(datePickerFormatDate(getLuxonDate(vaccination.renewalDate))) : null);
    setVaxType(vaccination?.type);
  };

  const onActionVaccinationSubmit = async (event: any, ...args: Array<string | null>) => {
    event.preventDefault();
    const actionType = args[0];
    const actionArg = args[1];
    const actionHasConfirmDialogStep = args[2];
    if (actionHasConfirmDialogStep === "NEEDS_CONFIRMATION") {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(translate('are_you_sure_delete_vaccine'))) {
        onActionVaccinationSubmit(event, actionType, actionArg);
      }
      return;
    }
    if(actionType === "ADD") {
      try {
        const data = {
          vaccines: [
            {
              vaccineName: newVaxName,
              administeredDate: newVaxDate?.toISOString().split(".")[0] + "Z",
              renewalDate: (newVaxRenewalDate) ? newVaxRenewalDate?.toISOString().split(".")[0] + "Z" : null,
              type: vaxType,
            }
          ]
        };
        const result = await petPatch(
          pet?.kinshipPetId,
          data
        );
        clearVaccineInputs();
        onActionVaccination(result);
      } catch (error) {
        console.error(error);
      }
    } else if(actionType === "DELETE") {
      const result = await deletePetVaccination(
        pet?.kinshipPetId,
        actionArg,
      );
      clearVaccineInputs();
      onActionVaccination(result);
    }  else if(actionType === "DELETE_ALL") {
      const result = await deleteAllPetVaccination(pet?.kinshipPetId);
      clearVaccineInputs();
      onActionVaccination(result);
    } else if(actionType === "EDIT") {
      const result = await editPetVaccination(
        pet?.kinshipPetId,
        oldVaccineTypeToUpdate,
        newVaxName,
        newVaxDate?.toISOString().split(".")[0] + "Z",
        (newVaxRenewalDate) ? newVaxRenewalDate?.toISOString().split(".")[0] + "Z" : null,
        vaxType
      );
      clearVaccineInputs();
      onActionVaccination(result);
    }
  };

  const clearVaccineInputs = (): void => {
    setNewVaxDate(new Date());
    setNewVaxRenewalDate(getDateYearPlusOne());
    setNewVaxName("");
    setVaxType("");
    setIsUpdateVaccination(false);
    setOldVaccineTypeToUpdate("");
  };

  const onVaccineTypeChange = async (type: string | null) => {
    if(type) {
      setVaxType(type);
    }
  };

  const onClearDatePickerSelection = (event: any) => {
    event.preventDefault();
    setNewVaxRenewalDate(null);
    renewalDatePickerRef.current?.setOpen(false);
  };

  const onRenewalDateChange = (date: Date): void => {
    if(date) {
      setNewVaxRenewalDate(date);
    } else {
      setNewVaxRenewalDate(null);
    }
  };

  const getVaxTypeValue = (type: string): string | undefined => {
    return vaxTypeList.find((vaxTypeObj: VaxType) => vaxTypeObj.internal_title === type)?.external_title;
  };

  return (
    <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
        {translate("vaccinations")}
      </Typography>
      <TableContainer sx={{ minWidth: 1200 }}>
      <Table
        sx={{
        width: "max-content"
        }}
      >
        <TableHead>
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontWeight: "medium" }}
              >
                {translate('name')}
              </Typography>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontWeight: "medium" }}
              >
                {translate('type')}
              </Typography>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontWeight: "medium" }}
              >
                {translate('administered_date')}
              </Typography>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontWeight: "medium" }}
              >
                {translate('renewal_date')}
              </Typography>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontWeight: "medium" }}
              >
                {translate('actions')}
              </Typography>
            </TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {record?.vaccine
            ? record?.vaccine?.map(
                (vaccination: Vaccination, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left" colSpan={2}>
                        {vaccination?.vaccineName}
                        <Typography 
                        variant="body1"
                        sx={{ color: resolveDueString(vaccination.renewalDate).color }} 
                        >
                        {resolveDueString(vaccination.renewalDate).text}
                        </Typography>
                    </TableCell>
                    <TableCell align="left" colSpan={2}>{getVaxTypeValue(vaccination?.type)} </TableCell>
                    <TableCell align="left" colSpan={2}>
                        {(vaccination.administeredDate || getLuxonDate(vaccination.administeredDate).isValid) ? getLuxonDate(vaccination.administeredDate).setLocale('zh').toLocaleString() : "NOT SET"}
                    </TableCell>
                    <TableCell align="left" colSpan={2}>
                        {(vaccination.renewalDate || getLuxonDate(vaccination.renewalDate).isValid) ? getLuxonDate(vaccination.renewalDate).setLocale('zh').toLocaleString() : "NOT SET"}
                    </TableCell>
                    <TableCell align="left" colSpan={2}>
                        <Stack direction="row" spacing={1}>
                        <IconButton 
                            color="primary"
                            aria-label="edit"
                            onClick={(event) => onEditVaccinationSubmit(event, vaccination)}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            color="primary"
                            aria-label={translate('delete')}
                            onClick={(event) => onActionVaccinationSubmit(event, "DELETE", vaccination?.type, "NEEDS_CONFIRMATION")}
                        >
                            <DeleteIcon />
                        </IconButton>
                        </Stack>
                    </TableCell>
                  </TableRow>
                );
              }
            )
            : <Box>{translate('no_vaccinations')}</Box>}
        <TableRow>
            <TableCell align="left" colSpan={2}>
            <TextField
                id="newName"
                name="newName"
                label={translate('new_name')}
                variant="outlined"
                size="small"
                value={newVaxName}
                onChange={(e) => setNewVaxName(e.target.value)}
            />
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Select
                displayEmpty
                value={vaxType}
                onChange={(e) =>
                onVaccineTypeChange(e.target.value)
                }
                sx={{ height: 40 }}
              >
                {vaxTypeList.map((val) => (
                <MenuItem key={val.id} value={val?.internal_title}>
                    {val?.external_title}
                </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell align="left" colSpan={2}>
            <DatePicker
                selected={newVaxDate || new Date()}
                onChange={(date: Date) => setNewVaxDate(date)}
                dateFormat="yyyy/MM/dd"
                customInput={
                <TextField
                    id="newVaxDate"
                    name="newVaxDate"
                    label={translate('administered_date')}
                    variant="outlined"
                    size="small"
                />
            }
            />
            </TableCell>
            <TableCell align="left" colSpan={2}>
            <DatePicker
                ref={renewalDatePickerRef}
                selected={newVaxRenewalDate}
                onChange={(date: Date) => onRenewalDateChange(date)}
                dateFormat="yyyy/MM/dd"
                isClearable
                customInput={
                <TextField
                    id="newVaxRenewalDate"
                    name="newVaxRenewalDate"
                    label={translate('renewal_date')}
                    variant="outlined"
                    size="small"
                />
                }
            >
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '5vh' }}
                >
                <Grid item xs={3}>
                    <Stack direction="row" spacing={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={(event) => onClearDatePickerSelection(event)}
                    >
                        {translate('clear')}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={(event) => {
                        event.preventDefault();
                        renewalDatePickerRef.current?.setOpen(false);
                        }}
                    >
                        {translate('apply')}
                    </Button>
                    </Stack>
                </Grid>
                </Grid>
            </DatePicker>
            </TableCell>
            <TableCell align="left" colSpan={2}>
            <Stack direction="row" spacing={1}>
                <Button
                type="submit"
                variant="contained"
                onClick={(event) => onActionVaccinationSubmit(event, `${(isUpdateVaccination) ? "EDIT" : "ADD"}`)}
                >
                {(isUpdateVaccination) ? translate('update_vaccination') : translate('add_vaccination')}
                </Button>
            </Stack>
            </TableCell>
        </TableRow>
        </TableBody>
    </Table>
    </TableContainer>
</Box>
 );
}
