
import { translate } from "./localisation";
import { DateTime } from "luxon";
import { DueObject } from "./kinshipTypes";
import colors from "./colors";

/*
        const ack = {
          petProfiles: petFetch()
        }

        const keys = Object.keys(ack)
        const values = await Promise.allSettled(keys.map(key => ack[key]))
        const result = {}
        for(let i=0; i<values.length; i++) {
          const key = keys[i]
          result[key] = values[i].value
        }
      */

export const datePickerFormatDate = (luxonDate: any) => {
  return luxonDate.isValid
    ? luxonDate.setLocale('zh').toLocaleString()
    : new Date();
};

export const getDateYearPlusOne = () : Date => {
  const splitDate = getVaxDateFormated(null).toString().split('/');
  return new Date(Number.parseInt(splitDate[0]) + 1, Number.parseInt(splitDate[2]), Number.parseInt(splitDate[1]));
}

export const getLuxonDate = (dateString: Date): any => {
  if(isValidDateString(dateString)) {
    return DateTime.fromISO(dateString?.toString().split("Z")[0])
  } else {
    return false;
  }
};

export const isVaccineOverdue = (renewalDate: Date) => renewalDate && numberOfDaysPast(renewalDate) < 1;

export const isVaccineDueSoon = (renewalDate: Date) => renewalDate &&
    numberOfDaysPast(renewalDate) <= 30 &&
    numberOfDaysPast(renewalDate) >= 1;

export const numberOfDaysPast = (dateString: Date): number => {
  const date = new Date();
  const diffInTime = Date.parse(dateString?.toString()) - date?.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);
  return diffInDays;
}

export const resolveDueString = (dateString: Date): DueObject => {
  if(isValidDateString(dateString)) {
    const isOverdue = isVaccineOverdue(dateString);
    const isDuesoon = isVaccineDueSoon(dateString);

    if(isOverdue) {
      return {
        text: 'Overdue',
        color: colors.orangeDark
      };
    } else if (isDuesoon) {
      return {
        text: 'Due soon',
        color: colors.tealDark
      };
    }
  } 
  
  return getLuxonDate(dateString).isValid ? {
    text: 'Up to date',
    color: colors.green
  } : {
    text: '',
    color: colors.white
  }
};

const isValidDateString = (dateString: Date): boolean => (dateString && dateString !== null && typeof dateString !== 'undefined');

const getVaxDateFormated = (dateVal: Date | null) => {
  if(!dateVal) {
    const newDate = new Date();
    return [
      newDate.toLocaleString('default', {year: 'numeric'}), 
      newDate.toLocaleString('default', {month: '2-digit'}), 
      newDate.toLocaleString('default', {day: '2-digit'})
    ].join('/');
  } else {
    return dateVal;
  }
};

export const getActionTypeTranslation = (arg?: string): string => {
  switch(arg) {
    case "ADD":
      return translate('dialogs.add-text');

    case "DELETE":
      return translate('dialogs.delete-text');

    case "DELETE_ALL":
      return translate('delete_all');

    case "EDIT":
      return translate('dialogs.edit-text');

    default:
      return translate('dialogs.default-action-text');
  }
};
